<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading
          heading="New Branch"
          sub-heading="All work is scheduled around your branches and the teams associated with them."
      />
    </column>
    <column class="is-narrow is-flex is-align-items-center">
      <router-link :to="{ name: 'branches' }">Back</router-link>
    </column>
  </columns>
  <form>
    <columns>
      <column>
        <text-input
          required
          classes="is-medium is-rounded"
          :error="$root.errors.name"
          :value="branch.name"
          @input="updateName">
          Name
        </text-input>
      </column>
      <column>
        <data-selector
          required
          classes="is-medium is-rounded"
          searchable
          :items="user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.branch_manager_uuid"
          :value="branch.branch_manager_uuid"
          @input="setBranchManager">
          Branch Manager
        </data-selector>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          multiple
          searchable
          :items="user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.users"
          :value="branch.users"
          @input="updateUsers">
          Staff
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="creating"
          @submit="createBranch">Create branch
        </submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import findIndex from 'lodash/findIndex'

export default {

  data: () => ({
    loading: true,
    creating: false,
  }),

  async beforeCreate() {
    await this.$store.dispatch('user/loadList')
    this.loading = false
  },

  beforeDestroy() {
    this.clearBranch()
  },

  methods: {
    ...mapMutations('branch', [
      'updateName',
      'clearBranch',
      'updateUsers',
      'updateBranchManager'
    ]),
    setBranchManager(userId) {
      if(findIndex(this.branch.users, user => user.uuid == userId) === -1) {
        this.updateUsers(this.branch.users.push(this.user_list.find(user => user.uuid === userId)))
      }
      this.updateBranchManager(userId)
    },
    createBranch() {
      this.creating = true
      this.$store.dispatch('branch/create').then(() => {
        this.$toast.success('Branch successfully created')
        this.$router.push({ name: 'branches' })
        this.creating = false
      }).catch(() => {
        this.creating = false
      })
    }
  },

  computed: {
    ...mapGetters('branch', [
      'branch'
    ]),
    ...mapGetters('user', [
      'user_list'
    ])
  }

}
</script>